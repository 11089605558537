import * as React from "react";
import { Link, HeadFC } from "gatsby";
import { SEO } from "../components/SEO";

const NotFoundPage = () => {
  return (
    <main className="p-4">
      <p>This page does not exist.</p>
      <Link className="text-heading hover:text-primary" to="/">
        Go home
      </Link>
      .
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <SEO title="Not found" />;
